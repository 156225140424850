<template>
  <div>
    <invoice-list-filter :user-filter.sync="userFilter" :date-from-filter.sync="dateFromFilter"
      :date-to-filter.sync="dateToFilter" :user-options="userOptions" :client-options="clientOptions"
      :client-filter.sync="clientFilter" :status-filter.sync="statusFilter" :status-options="statusOptions"  />
    <b-card no-body class="mb-0">

      <b-overlay :show="tableLoading" spinner-variant="primary" spinner-type="grow" spinner-small rounded="sm">
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <!-- Per Page -->
            <b-col cols="12" class="mb-2" style="text-align: center">
              <h3 class="mb-0">發票報表</h3>
            </b-col>

            <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0" v-if="false">
              <label>Show</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector d-inline-block mx-50" />
              <label>entries</label>
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="12">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="exportReport">
                  <span class="text-nowrap">導出Excel</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table ref="refInvoiceListTable" class="position-relative" :items="fetchInvoices" responsive
          :fields="tableReportColumns" primary-key="id" :sort-by.sync="sortBy" show-empty
          empty-text="No matching records found" :sort-desc.sync="isSortDirDesc">
          <!-- Column: Create Date -->
          <template #cell(created_at)="data">
            {{ getCorrectDateTime(data.item.created_at) }}
          </template>

          <!-- Column: Amount -->
          <template #cell(total_amount)="data">
            {{ getNumberFormat(data.item.total_amount) }}
          </template>
          <!-- Column: Amount -->
          <template #cell(total_paid)="data">
            {{ getNumberFormat(data.item.total_paid) }}
          </template>
          <!-- Column: Total Remain -->
          <template #cell(total_remain)="data">
            {{ getNumberFormat((data.item.total_amount - data.item.total_paid) < 0 ? 0 : (data.item.total_amount -
              data.item.total_paid)) }} </template>

              <!-- Column: Client Code -->
              <template #cell(client)="data">
                <b-link :to="{
                  name: 'clients-detail',
                  params: { id: data.item.client.id },
                }">{{ data.item.client.client_code }}</b-link>
              </template>

              <!-- Column: Contract -->
              <template #cell(contract)="data">
                <b-badge class="text-capitalize" v-if="data.item.contract">
                  {{ data.item.contract.contract_id }}
                </b-badge>
              </template>
              <!-- Column: IS PAID -->
              <template #cell(is_paid)="data">
                <feather-icon v-if="data.item.total_paid >= data.item.total_amount" icon="CheckIcon" size="18"
                  class="mr-50 text-success" />
              </template>
              <!-- Column: IS PAID -->
              <template #cell(is_audit)="data">
                <feather-icon v-if="data.item.is_audit" icon="CheckIcon" size="18" class="mr-50 text-success" />
              </template>
              <!-- Column: Project -->
              <template #cell(project)="data">
                <b-badge class="text-capitalize" v-if="data.item.project">
                  {{ data.item.project.project_id }}
                </b-badge>
              </template>
              <template #cell(created_by)="data">
                <span v-if="data.item.user">{{ data.item.user.name }}</span>
              </template>
        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2" v-if="false">
        <b-row>
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            ">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            ">
            <b-pagination v-model="currentPage" :total-rows="totalInvoices" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BSidebar,
  BForm,
  BFormGroup,
  BOverlay,
  BFormDatepicker,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted, onMounted } from "@vue/composition-api";
import invoiceStoreModule from "@/views/invoice/invoiceStoreModule.js";
import useInvoicesList from "@/views/invoice/invoices-list/useInvoicesList.js";
import InvoiceListFilter from "@/views/invoice/invoices-list/InvoiceListFilter.vue"

import Ripple from "vue-ripple-directive";
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";

import { quillEditor } from "vue-quill-editor";

export default {
  data() {
    return {
      pdfUrl: "",
      modalId: '-invoice-list',
      invoice_id: null,
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BSidebar,
    BForm,
    BFormGroup,
    BOverlay,
    BFormDatepicker,
    quillEditor,
    InvoiceListFilter,
    BFormTextarea,
  },
  methods: {
    getNumberFormat(amount) {
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "HKD",
        minimumFractionDigits: 0,
      });
      return formatter.format(amount);
    },
    exportReport() {
      this.$swal({
        title: "確定導出Excel?",
        showCancelButton: true,
        confirmButtonText: "下載",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          this.$store
            .dispatch("invoice/exportInvoices", {
              sortBy: this.sortBy,
              sortDesc: this.isSortDirDesc,
              create_id: this.userFilter,
              client_id: this.clientFilter,
              date_from: this.dateFromFilter,
              date_to: this.dateToFilter,
              payment_status: this.statusFilter
            })
            .then((response) => {
              // create file link in browser's memory
              const href = URL.createObjectURL(response.data);
              // create "a" HTLM element with href to file & click
              const link = document.createElement("a");
              link.href = href;
              //get current datetime
              const current_datetime = new Date();
              const formatted_date =
                current_datetime.getFullYear() +
                "-" +
                (current_datetime.getMonth() + 1) +
                "-" +
                current_datetime.getDate() +
                " " +
                current_datetime.getHours() +
                "-" +
                current_datetime.getMinutes() +
                "-" +
                current_datetime.getSeconds();
              link.setAttribute(
                "download",
                "Invoices" + formatted_date + ".xlsx"
              ); // or any other extension
              document.body.appendChild(link);
              link.click();

              // clean up "a" element & remove ObjectURL
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            })
            .catch((error) => {
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
  },
  setup() {
    const INVOICE_STORE_MODULE_NAME = "invoice";
    const isAddNewInvoiceSidebarActive = ref(false);
    const showUpload = ref(true);

    const uploadUrl = ref(process.env.VUE_APP_SERVICE_URL + "/invoice");
    const headerObj = {
      Authorization: "Bearer " + localStorage.getItem("accessToken"),
    };
    const paymentOptions = ref([]);
    const fileList = ref([]);
    const invoice = ref({
      id: 0,
      application_codes: [],
      payment_method_id: [],
      application_remark: "",
    });
    const loading = ref(false);
    const tableLoading = ref(false);
    const blankInvoiceData = {
      id: 0,
      application_codes: [],
      application_remark: "",
    };

    if (!store.hasModule(INVOICE_STORE_MODULE_NAME))
      store.registerModule(INVOICE_STORE_MODULE_NAME, invoiceStoreModule);

    store
      .dispatch(INVOICE_STORE_MODULE_NAME + "/fetchPaymentMethods")
      .then((response) => {
        paymentOptions.value = response.data.paymentmethods;
      })
      .catch((error) => {
        console.log(error.response);
      });

    const {
      fetchInvoices,
      tableReportColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refInvoiceListTable,
      ability,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      statusFilter,
      statusOptions
    } = useInvoicesList();

    //Filter 
    const userOptions = ref([])
    const clientOptions = ref([])
    const receiptTemplateOptions = ref([])


    store.dispatch('app/fetchOptionList', { user: true, client: true })
      .then(response => {
        userOptions.value = response.data.users;
        clientOptions.value = response.data.clients;
        receiptTemplateOptions.value = response.data.receipt_templates;
      })
      .then(error => {
        console.log('Error in fetching User error', error)
      });

    onMounted(() => {
      currentPage.value = 1;
      perPage.value = null;
    });

    return {
      INVOICE_STORE_MODULE_NAME,
      fetchInvoices,
      tableReportColumns,
      perPage,
      currentPage,
      totalInvoices,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      refInvoiceListTable,
      ability,
      isAddNewInvoiceSidebarActive,
      invoice,
      loading,
      showUpload,
      uploadUrl,
      headerObj,
      fileList,
      paymentOptions,
      userOptions,
      clientOptions,
      userFilter,
      clientFilter,
      dateFromFilter,
      dateToFilter,
      statusFilter,
      tableLoading,
      receiptTemplateOptions,
      statusOptions
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.badge {
  display: block;
  margin: 5px;
  max-width: 200px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
